import React, { useState } from 'react';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';

import './index.scss';
import './team-builder.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { graphql, Link } from 'gatsby';
import { ILimbusCharacter } from '../../modules/common/model/graphql-types';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LCTeamBuilderSingleCharacter } from '../../modules/lc/team-builder/single-character';
import { LimbusCharacter } from '../../modules/lc/common/components/lc-character';

interface ILimbusCharacterNodes {
  nodes: ILimbusCharacter[];
}

interface ILimbusCharacterEntry {
  allCharacters: ILimbusCharacterNodes;
}

interface IProps {
  data: ILimbusCharacterEntry;
}

const LimbusTeamBuilderPage: React.FC<IProps> = ({ data }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [fullList, setFullList] = useState(data.allCharacters.nodes);
  const [favList, setFavList] = useState([]);
  const [sumEnvy, setSumEnvy] = useState(0);
  const [sumGloom, setSumGloom] = useState(0);
  const [sumGluttony, setSumGluttony] = useState(0);
  const [sumLust, setSumLust] = useState(0);
  const [sumPride, setSumPride] = useState(0);
  const [sumSloth, setSumSloth] = useState(0);
  const [sumWrath, setSumWrath] = useState(0);

  const resetStuff = () => {
    setFavList([]);
    setSumEnvy(0);
    setSumGloom(0);
    setSumGluttony(0);
    setSumLust(0);
    setSumPride(0);
    setSumSloth(0);
    setSumWrath(0);
  };

  const handleFavAddClick = (e) => {
    const findItem = fullList.find((item) => item.unitId === e);

    if (findItem) {
      const checkIfIsFav = favList.find((item) => item.unitId === e);
      let passive1type = '';
      let passive1cost = '';
      let passive2type = '';
      let passive2cost = '';

      if (!checkIfIsFav) {
        findItem.passives.map((passive, index) => {
          if (index === 0) {
            passive1type = passive.type;
            passive1cost = passive.cost;
          }
          if (index === 1) {
            passive2type = passive.type;
            passive2cost = passive.cost;
          }
        });

        if (findItem.affinityDetailed.affinity_1 === 'Wrath') {
          setSumWrath(sumWrath + 3);
        } else if (findItem.affinityDetailed.affinity_1 === 'Lust') {
          setSumLust(sumLust + 3);
        } else if (findItem.affinityDetailed.affinity_1 === 'Sloth') {
          setSumSloth(sumSloth + 3);
        } else if (findItem.affinityDetailed.affinity_1 === 'Gluttony') {
          setSumGluttony(sumGluttony + 3);
        } else if (findItem.affinityDetailed.affinity_1 === 'Gloom') {
          setSumGloom(sumGloom + 3);
        } else if (findItem.affinityDetailed.affinity_1 === 'Pride') {
          setSumPride(sumPride + 3);
        } else if (findItem.affinityDetailed.affinity_1 === 'Envy') {
          setSumEnvy(sumEnvy + 3);
        }

        if (findItem.affinityDetailed.affinity_2 === 'Wrath') {
          setSumWrath(sumWrath + 2);
        } else if (findItem.affinityDetailed.affinity_2 === 'Lust') {
          setSumLust(sumLust + 2);
        } else if (findItem.affinityDetailed.affinity_2 === 'Sloth') {
          setSumSloth(sumSloth + 2);
        } else if (findItem.affinityDetailed.affinity_2 === 'Gluttony') {
          setSumGluttony(sumGluttony + 2);
        } else if (findItem.affinityDetailed.affinity_2 === 'Gloom') {
          setSumGloom(sumGloom + 2);
        } else if (findItem.affinityDetailed.affinity_2 === 'Pride') {
          setSumPride(sumPride + 2);
        } else if (findItem.affinityDetailed.affinity_2 === 'Envy') {
          setSumEnvy(sumEnvy + 2);
        }

        if (findItem.affinityDetailed.affinity_3 === 'Wrath') {
          setSumWrath(sumWrath + 1);
        } else if (findItem.affinityDetailed.affinity_3 === 'Lust') {
          setSumLust(sumLust + 1);
        } else if (findItem.affinityDetailed.affinity_3 === 'Sloth') {
          setSumSloth(sumSloth + 1);
        } else if (findItem.affinityDetailed.affinity_3 === 'Gluttony') {
          setSumGluttony(sumGluttony + 1);
        } else if (findItem.affinityDetailed.affinity_3 === 'Gloom') {
          setSumGloom(sumGloom + 1);
        } else if (findItem.affinityDetailed.affinity_3 === 'Pride') {
          setSumPride(sumPride + 1);
        } else if (findItem.affinityDetailed.affinity_3 === 'Envy') {
          setSumEnvy(sumEnvy + 1);
        }

        setFavList([
          ...favList,
          {
            unitId: findItem.unitId,
            name: findItem.name,
            slug: findItem.slug,
            baseCharacter: findItem.baseCharacter,
            skill1: findItem.affinityDetailed.affinity_1,
            skill2: findItem.affinityDetailed.affinity_2,
            skill3: findItem.affinityDetailed.affinity_3,
            passive1c: passive1cost,
            passive1v: passive1type,
            passive2c: passive2cost,
            passive2v: passive2type
          }
        ]);
      } else {
        setFavList(favList.filter((item) => item.unitId !== e));
        if (findItem.affinityDetailed.affinity_1 === 'Wrath') {
          setSumWrath(sumWrath - 3);
        } else if (findItem.affinityDetailed.affinity_1 === 'Lust') {
          setSumLust(sumLust - 3);
        } else if (findItem.affinityDetailed.affinity_1 === 'Sloth') {
          setSumSloth(sumSloth - 3);
        } else if (findItem.affinityDetailed.affinity_1 === 'Gluttony') {
          setSumGluttony(sumGluttony - 3);
        } else if (findItem.affinityDetailed.affinity_1 === 'Gloom') {
          setSumGloom(sumGloom - 3);
        } else if (findItem.affinityDetailed.affinity_1 === 'Pride') {
          setSumPride(sumPride - 3);
        } else if (findItem.affinityDetailed.affinity_1 === 'Envy') {
          setSumEnvy(sumEnvy - 3);
        }

        if (findItem.affinityDetailed.affinity_2 === 'Wrath') {
          setSumWrath(sumWrath - 2);
        } else if (findItem.affinityDetailed.affinity_2 === 'Lust') {
          setSumLust(sumLust - 2);
        } else if (findItem.affinityDetailed.affinity_2 === 'Sloth') {
          setSumSloth(sumSloth - 2);
        } else if (findItem.affinityDetailed.affinity_2 === 'Gluttony') {
          setSumGluttony(sumGluttony - 2);
        } else if (findItem.affinityDetailed.affinity_2 === 'Gloom') {
          setSumGloom(sumGloom - 2);
        } else if (findItem.affinityDetailed.affinity_2 === 'Pride') {
          setSumPride(sumPride - 2);
        } else if (findItem.affinityDetailed.affinity_2 === 'Envy') {
          setSumEnvy(sumEnvy - 2);
        }

        if (findItem.affinityDetailed.affinity_3 === 'Wrath') {
          setSumWrath(sumWrath - 1);
        } else if (findItem.affinityDetailed.affinity_3 === 'Lust') {
          setSumLust(sumLust - 1);
        } else if (findItem.affinityDetailed.affinity_3 === 'Sloth') {
          setSumSloth(sumSloth - 1);
        } else if (findItem.affinityDetailed.affinity_3 === 'Gluttony') {
          setSumGluttony(sumGluttony - 1);
        } else if (findItem.affinityDetailed.affinity_3 === 'Gloom') {
          setSumGloom(sumGloom - 1);
        } else if (findItem.affinityDetailed.affinity_3 === 'Pride') {
          setSumPride(sumPride - 1);
        } else if (findItem.affinityDetailed.affinity_3 === 'Envy') {
          setSumEnvy(sumEnvy - 1);
        }
      }
    }
  };

  const handleRemoveClick = (e) => {
    const findItem = favList.find((item) => item.unitId === e);
    if (findItem.skill1 === 'Wrath') {
      setSumWrath(sumWrath - 3);
    } else if (findItem.skill1 === 'Lust') {
      setSumLust(sumLust - 3);
    } else if (findItem.skill1 === 'Sloth') {
      setSumSloth(sumSloth - 3);
    } else if (findItem.skill1 === 'Gluttony') {
      setSumGluttony(sumGluttony - 3);
    } else if (findItem.skill1 === 'Gloom') {
      setSumGloom(sumGloom - 3);
    } else if (findItem.skill1 === 'Pride') {
      setSumPride(sumPride - 3);
    } else if (findItem.skill1 === 'Envy') {
      setSumEnvy(sumEnvy - 3);
    }

    if (findItem.skill2 === 'Wrath') {
      setSumWrath(sumWrath - 2);
    } else if (findItem.skill2 === 'Lust') {
      setSumLust(sumLust - 2);
    } else if (findItem.skill2 === 'Sloth') {
      setSumSloth(sumSloth - 2);
    } else if (findItem.skill2 === 'Gluttony') {
      setSumGluttony(sumGluttony - 2);
    } else if (findItem.skill2 === 'Gloom') {
      setSumGloom(sumGloom - 2);
    } else if (findItem.skill2 === 'Pride') {
      setSumPride(sumPride - 2);
    } else if (findItem.skill2 === 'Envy') {
      setSumEnvy(sumEnvy - 2);
    }

    if (findItem.skill3 === 'Wrath') {
      setSumWrath(sumWrath - 1);
    } else if (findItem.skill3 === 'Lust') {
      setSumLust(sumLust - 1);
    } else if (findItem.skill3 === 'Sloth') {
      setSumSloth(sumSloth - 1);
    } else if (findItem.skill3 === 'Gluttony') {
      setSumGluttony(sumGluttony - 1);
    } else if (findItem.skill3 === 'Gloom') {
      setSumGloom(sumGloom - 1);
    } else if (findItem.skill3 === 'Pride') {
      setSumPride(sumPride - 1);
    } else if (findItem.skill3 === 'Envy') {
      setSumEnvy(sumEnvy - 1);
    }
    setFavList(favList.filter((item) => item.unitId !== e));
  };

  return (
    <DashboardLayout className={'generic-page team-builder-lc'} game="lc">
      <ul className="breadcrumb">
        <li>
          <Link to="/limbus-company/">Limbus Company</Link>
        </li>
        <li className="divider">/</li>
        <li>Team Builder</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../images/limbuscompany/categories/category_3.jpg"
            alt="Identities"
          />
        </div>
        <div className="page-details">
          <h1>Team Builder</h1>
          <h2>
            A handy team builder to easier plan your teams in Limbus Company.
          </h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      <div className="page-content ">
        <SectionHeader
          title="Team Builder"
          firstControl={
            <Button
              onClick={resetStuff}
              variant="primary"
              className="remove-me"
            >
              <FontAwesomeIcon icon={faXmark} width="16" /> Reset team
            </Button>
          }
          customClass="section-reset"
        />
        <Button
          onClick={resetStuff}
          variant="primary"
          className="remove-me button-mobile"
        >
          <FontAwesomeIcon icon={faXmark} width="16" /> Reset team
        </Button>
        <div className="res-row">
          <div className="single-res">
            <StaticImage
              src="../../images/limbuscompany/icons/affinity_wrath_big.png"
              alt="Wrath"
            />
            <p>
              Wrath:{' '}
              <strong className={`${sumWrath > 0 ? 'increase' : ''}`}>
                {sumWrath}
              </strong>
            </p>
          </div>
          <div className="single-res">
            <StaticImage
              src="../../images/limbuscompany/icons/affinity_lust_big.png"
              alt="Lust"
            />
            <p>
              Lust:{' '}
              <strong className={`${sumLust > 0 ? 'increase' : ''}`}>
                {sumLust}
              </strong>
            </p>
          </div>
          <div className="single-res">
            <StaticImage
              src="../../images/limbuscompany/icons/affinity_sloth_big.png"
              alt="Lust"
            />
            <p>
              Sloth:{' '}
              <strong className={`${sumSloth > 0 ? 'increase' : ''}`}>
                {sumSloth}
              </strong>
            </p>
          </div>
          <div className="single-res">
            <StaticImage
              src="../../images/limbuscompany/icons/affinity_gluttony_big.png"
              alt="Lust"
            />
            <p>
              Gluttony:{' '}
              <strong className={`${sumGluttony > 0 ? 'increase' : ''}`}>
                {sumGluttony}
              </strong>
            </p>
          </div>
          <div className="single-res">
            <StaticImage
              src="../../images/limbuscompany/icons/affinity_gloom_big.png"
              alt="Lust"
            />
            <p>
              Gloom:{' '}
              <strong className={`${sumGloom > 0 ? 'increase' : ''}`}>
                {sumGloom}
              </strong>
            </p>
          </div>
          <div className="single-res">
            <StaticImage
              src="../../images/limbuscompany/icons/affinity_pride_big.png"
              alt="Lust"
            />
            <p>
              Pride:{' '}
              <strong className={`${sumPride > 0 ? 'increase' : ''}`}>
                {sumPride}
              </strong>
            </p>
          </div>
          <div className="single-res">
            <StaticImage
              src="../../images/limbuscompany/icons/affinity_envy_big.png"
              alt="Lust"
            />
            <p>
              Envy:{' '}
              <strong className={`${sumEnvy > 0 ? 'increase' : ''}`}>
                {sumEnvy}
              </strong>
            </p>
          </div>
        </div>
        <Row xs={1} xl={2} xxl={4} className="current-team">
          {favList.map((e, index) => (
            <LCTeamBuilderSingleCharacter
              index={index}
              handleRemoveClick={handleRemoveClick}
              favList={favList}
              element={e}
              key={index}
              sumWrath={sumWrath}
              sumLust={sumLust}
              sumSloth={sumSloth}
              sumGluttony={sumGluttony}
              sumGloom={sumGloom}
              sumPride={sumPride}
              sumEnvy={sumEnvy}
            />
          ))}
          {favList.length < 1 && (
            <Col>
              <div className="box placeholder-spot role-0">
                <div className="character-box-empty">
                  <div className="placeholder-icon"></div>
                  <h5>Empty spot</h5>
                  <div className="base-info">
                    <div className={`pill`}>
                      3x <strong>? Sin</strong>
                    </div>
                    <div className={`pill`}>
                      2x <strong>? Sin</strong>
                    </div>
                  </div>
                  <div className="base-info">
                    <div className={`pill`}>
                      1x <strong>? Sin</strong>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          )}
          {favList.length < 2 && (
            <Col>
              <div className="box placeholder-spot role-0">
                <div className="character-box-empty ">
                  <div className="placeholder-icon"></div>
                  <h5>Empty spot</h5>
                  <div className="base-info">
                    <div className={`pill`}>
                      3x <strong>? Sin</strong>
                    </div>
                    <div className={`pill`}>
                      2x <strong>? Sin</strong>
                    </div>
                  </div>
                  <div className="base-info">
                    <div className={`pill`}>
                      1x <strong>? Sin</strong>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          )}
          {favList.length < 3 && (
            <Col>
              <div className="box placeholder-spot role-0">
                <div className="character-box-empty">
                  <div className="placeholder-icon"></div>
                  <h5>Empty spot</h5>
                  <div className="base-info">
                    <div className={`pill`}>
                      3x <strong>? Sin</strong>
                    </div>
                    <div className={`pill`}>
                      2x <strong>? Sin</strong>
                    </div>
                  </div>
                  <div className="base-info">
                    <div className={`pill`}>
                      1x <strong>? Sin</strong>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          )}
          {favList.length < 4 && (
            <Col>
              <div className="box placeholder-spot role-0">
                <div className="character-box-empty">
                  <div className="placeholder-icon"></div>
                  <h5>Empty spot</h5>
                  <div className="base-info">
                    <div className={`pill`}>
                      3x <strong>? Sin</strong>
                    </div>
                    <div className={`pill`}>
                      2x <strong>? Sin</strong>
                    </div>
                  </div>
                  <div className="base-info">
                    <div className={`pill`}>
                      1x <strong>? Sin</strong>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          )}
          {favList.length < 5 && (
            <Col>
              <div className="box placeholder-spot role-0">
                <div className="character-box-empty">
                  <div className="placeholder-icon"></div>
                  <h5>Empty spot</h5>
                  <div className="base-info">
                    <div className={`pill`}>
                      3x <strong>? Sin</strong>
                    </div>
                    <div className={`pill`}>
                      2x <strong>? Sin</strong>
                    </div>
                  </div>
                  <div className="base-info">
                    <div className={`pill`}>
                      1x <strong>? Sin</strong>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          )}
          {favList.length < 6 && (
            <Col>
              <div className="box placeholder-spot role-0">
                <div className="character-box-empty">
                  <div className="placeholder-icon"></div>
                  <h5>Empty spot</h5>
                  <div className="base-info">
                    <div className={`pill`}>
                      3x <strong>? Sin</strong>
                    </div>
                    <div className={`pill`}>
                      2x <strong>? Sin</strong>
                    </div>
                  </div>
                  <div className="base-info">
                    <div className={`pill`}>
                      1x <strong>? Sin</strong>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          )}
          {favList.length < 7 && (
            <Col>
              <div className="box placeholder-spot role-0">
                <div className="character-box-empty">
                  <div className="placeholder-icon"></div>
                  <h5>Empty spot</h5>
                  <div className="base-info">
                    <div className={`pill`}>
                      3x <strong>? Sin</strong>
                    </div>
                    <div className={`pill`}>
                      2x <strong>? Sin</strong>
                    </div>
                  </div>
                  <div className="base-info">
                    <div className={`pill`}>
                      1x <strong>? Sin</strong>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          )}
        </Row>
        <h5>Characters</h5>
        <div
          className={`employees-container ${
            favList.length === 7 && 'full-list'
          }`}
        >
          {fullList.map((e, index) => {
            const isFav = favList.find((item) => item.unitId === e.unitId);
            return (
              <span key={index}>
                {isFav || favList.length < 7 ? (
                  <>
                    <Card
                      onClick={() => handleFavAddClick(e.unitId)}
                      className={`avatar-card ${isFav ? 'selected' : ''}`}
                    >
                      <LimbusCharacter
                        slug={e.slug}
                        mode="icon-no-link"
                        showLabel
                      />
                    </Card>
                  </>
                ) : (
                  <Card className="avatar-card">
                    <LimbusCharacter
                      slug={e.slug}
                      mode="icon-no-link"
                      showLabel
                    />
                  </Card>
                )}
              </span>
            );
          })}
        </div>
      </div>
    </DashboardLayout>
  );
};

export default LimbusTeamBuilderPage;

export const Head: React.FC = () => (
  <Seo
    title="Team Builder | Limbus Company | Prydwen Institute"
    description="A handy team builder to easier plan your teams in Limbus Company."
    game="limbus"
  />
);

export const pageQuery = graphql`
  query {
    allCharacters: allContentfulLimbusCharacters(
      sort: { fields: slug, order: ASC }
    ) {
      nodes {
        name
        unitId
        slug
        rarity
        baseCharacter
        attackType
        speciality
        affinity
        isNew
        ratings {
          beg
          end
        }
        isReviewPending
        tierListTags
        passives {
          passiveId
          name
          passiveType
          type
          cost
          category
          description {
            raw
          }
        }
        tierCategory
        affinityDetailed {
          affinity_1
          affinity_1_v
          affinity_2
          affinity_2_v
          affinity_3
          affinity_3_v
        }
        isReviewPending
      }
    }
  }
`;
